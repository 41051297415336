



























import Vue from 'vue';
import moment from 'moment';
import * as voucheApi from '@/api/offlineVouche';
export default Vue.extend({
  name: 'exportRecords',
  props: [],
  data() {
    return {
      showModal: false,
      tableLoading: false,
      recordData: {} as any,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      exportRecordsColumns: [
        {
          title: '编号',
          align: 'center',
          dataIndex: 'number',
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '导出时间',
          dataIndex: 'exportTime',
          align: 'center',
          scopedSlots: { customRender: 'exportTime' },
        },
        {
          title: '操作人',
          dataIndex: 'recUserName',
          align: 'center',
        },
      ],
      exportRecordsData: [] as any,
    };
  },
  methods: {
    moment,
    show(record) {
      this.showModal = true;
      this.recordData = record;
      this.getExportRecordsData();
    },
    // 获取导出记录列表数据
    getExportRecordsData() {
      this.tableLoading = true;
      voucheApi
        .getExportRecords(
          this.pagination.current,
          this.pagination.pageSize,
          this.recordData.voucherDefinitionCode
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.exportRecordsData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getExportRecordsData();
    },
  },
});
