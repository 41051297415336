






















































































































































































import Vue from 'vue';
import moment from 'moment';
import { message, Modal } from 'ant-design-vue';
import { downloadFile } from '@/utils/index';
import * as voucheApi from '@/api/offlineVouche';
import createActivity from './createActivity/index.vue';
import exportRecords from './exportRecords/index.vue';
import activityDetail from './activityDetail/index.vue';
export default Vue.extend({
  name: 'offlineVoucheList',
  components: {
    createActivity,
    exportRecords,
    activityDetail,
  },
  data() {
    return {
      tableLoading: false,
      searchForm: {
        name: '', // 活动名称
        voucherDefinitionCode: '', // 活动批次编号
        redeemCode: '', // 兑换码
        status: '', // 活动状态
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      offlineVoucheColumns: [
        {
          title: '活动名称',
          dataIndex: 'name',
          align: 'center',
          fixed: 'left',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '活动批次编号',
          dataIndex: 'voucherDefinitionCode',
          align: 'center',
        },
        {
          title: '满(元)',
          dataIndex: 'firstPrice',
          align: 'center',
        },
        {
          title: '减(元)',
          dataIndex: 'price',
          align: 'center',
        },
        {
          title: '生成数量',
          dataIndex: 'generateCount',
          align: 'center',
        },
        {
          title: '兑换码兑换有效期',
          dataIndex: 'redeemCodeDeadline',
          scopedSlots: { customRender: 'redeemCodeDeadline' },
          align: 'center',
        },
        // {
        //   title: '使用范围',
        //   dataIndex: 'availableName',
        //   align: 'center',
        // },
        {
          title: '代金券使用有效期',
          dataIndex: 'expiredType',
          scopedSlots: { customRender: 'expiredType' },
          align: 'center',
        },
        {
          title: '创建人',
          dataIndex: 'recUserName',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
          align: 'center',
        },
        {
          title: '活动状态',
          dataIndex: 'voucherDefinitionStatus',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'voucherDefinitionStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      offlineVoucheData: [] as any,
    };
  },
  mounted() {
    this.getOfflineVoucheList();
  },
  methods: {
    moment,
    // 获取线下代金券列表
    getOfflineVoucheList() {
      this.tableLoading = true;
      voucheApi
        .getOfflineVoucheList(
          this.searchForm.name,
          this.searchForm.voucherDefinitionCode,
          this.searchForm.redeemCode,
          this.searchForm.status,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.offlineVoucheData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getOfflineVoucheList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getOfflineVoucheList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getOfflineVoucheList();
    },
    // 新建线下活动按钮事件
    handleCreateActivity() {
      (this.$refs.createActivity as HTMLFormElement).show();
    },
    // 导出兑换码事件
    handleExportRedeemCode(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定导出兑换码吗？',
        okText: '是的，确定导出！',
        onOk: () => {
          return new Promise((resolve) => {
            voucheApi
              .voucherExport(record.voucherDefinitionCode)
              .then((res: any) => {
                downloadFile(res, record.name + '_生成兑换码');
              })
              .finally(() => {
                resolve(true);
              });
          });
        },
      });
    },
    // 导出记录事件
    handleExportRecords(record) {
      (this.$refs.exportRecords as HTMLFormElement).show(record);
    },
    // 作废事件
    handleCancel(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定作废吗？',
        okType: 'danger',
        okText: '是的，确定作废！',
        onOk: () => {
          return new Promise((resolve) => {
            voucheApi
              .discardActivityRecords(record.sequenceNbr)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 详情事件
    handleDetail(record) {
      (this.$refs.activityDetail as HTMLFormElement).show(
        record,
        this.searchForm.redeemCode
      );
    },
    // 发布事件
    handleRelease(record) {
      Modal.confirm({
        title: '操作提示',
        content: '确定发布吗？',
        okText: '是的，确定发布！',
        onOk: () => {
          return new Promise((resolve) => {
            voucheApi
              .releaseActivityRecords(record.sequenceNbr)
              .then((res) => {
                if (res.status === 200) {
                  message.success('操作成功！');
                } else {
                  message.error('操作失败！');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    // 编辑事件
    handleEdit(record) {
      (this.$refs.createActivity as HTMLFormElement).edit(record);
    },
    // 数据保存或发布成功事件回调
    formSaveSuccess() {
      this.handleSearchSubmit();
    },
  },
});
